<template>
    <div>
        <div style="display:flex;align-items: center;">
            <el-button type="primary" @click="dialogVisible=true" style="margin:10px">上传App新版本</el-button>
            <el-select placeholder="请选择教练端家长端" style="margin:10px">
                <el-option label="全部" value="all"></el-option>
                <el-option label="教练端" value="coach"></el-option>
                <el-option label="家长端" value="user"></el-option>
            </el-select>
            <el-button type="primary" style="margin:10px">查询</el-button>
        </div>
        <div style="padding:20px">
            <el-table :data="manageList" border style="width: 100%">
            <el-table-column prop="version" label="版本号" width="180"></el-table-column>
            <el-table-column prop="type" label="类型" width="180">
                <template #default="scope">
                    <span v-if="scope.row.type==='coach'">教练端</span>
                    <span v-if="scope.row.type==='user'">家长端</span>
                </template>
            </el-table-column>
            <el-table-column prop="url" label="下载地址"></el-table-column>
            <el-table-column prop="createTime" label="上传时间"></el-table-column>
            <el-table-column prop="desc" label="版本描述" >
                <template #default="scope">
                    <span>{{scope.row.desc.length>10?scope.row.desc.slice(0,10)+'...':scope.row.desc}}</span>
                </template>
            </el-table-column>
            <el-table-column  label="状态">
                <template #default="scope">
                    <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                        :active-value="1" :inactive-value="0"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作">
                <template #default="scope">
                    <div style="display:flex">
                        <el-button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        <el-button type="text" size="small" @click="handleDetail(scope.$index, scope.row)">详情</el-button>    
                    </div>     
                </template>
            </el-table-column>
            </el-table>
        </div>
        <el-dialog
            title="上传apk"
            v-model="dialogVisible"
            width="40%"
            :before-close="handleClose">
                <div style="margin-bottom:20px;display: flex;align-items: center;">
                    <div>请选择上传类型：</div>
                    <el-select placeholder="请选择教练端家长端">
                    <el-option label="教练端" value="coach"></el-option>
                    <el-option label="家长端" value="user"></el-option>
                </el-select>
                </div>
                <el-upload
                class="upload-demo"
                drag
                @change="handleChange"
                action="https://jsonplaceholder.typicode.com/posts/"
                multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
          
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'App版本管理',
        data() {
            return {
                dialogVisible:false,
                query:{ 
                    page:1,
                    pageSize:1,
                    type:'all'
                },
                manageList:[
                    {
                    id:1,
                    type:'coach',
                    name:'1.0.0',
                    createTime:'2020-01-01',
                    version:'1.0.0',
                    desc:'1.0.0',
                    status:1
                    },
                    {
                    id:2,
                    type:'user',
                    name:'1.0.0',
                    createTime:'2020-01-01',
                    version:'1.0.0',
                    desc:'1.0.0',
                    status:1
                    }
                ],

            }
        },
        methods: {
            handleChange(value) {
                console.log(value);
                let data = new FormData();
                data.append('file', value.raw);

            }
        },
    }
</script>